.DateRangePickerInput__withBorder {
  border-radius: unset;
  border: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.DayPicker_transitionContainer {
  min-height: 430px;
}

.CalendarDay__default:hover {
  background: #7ebaff;
  color: white;
  border: 1px solid #f9f9f9;
}

.CalendarDay__selected_span {
  background: #afd5ff;
  color: white;
  border: 1px solid #f9f9f9;
}

.CalendarDay__selected {
  background: #154b88;
  color: white;
  border: 1px solid #f9f9f9;
}

.CalendarDay__selected:hover {
  background: #7ebaff;
  color: white;
  border: 1px solid #f9f9f9;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #7ebaff;
  color: white;
  border: 1px solid #f9f9f9;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #154b88;
}

.DateInput_input {
  padding: unset;
  font-size: 14px;
}

.DateInput_input__focused {
  border-bottom: 2px solid #154b88;
}

.DateRangePickerInput_calendarIcon {
  padding: unset;
  padding-bottom: 3px;
  margin: unset;
  margin-left: 8px;
  margin-right: 8px;
}

.DateRangePickerInput_arrow {
  width: 24px;
}

.DateRangePickerInput_clearDates {
  padding: unset;
  transform: translateY(-60%);
}
